import React from "react";
import './style.css'
import Links from "./Links";
import conferenceCentre from './imgs/conferenceCentre.jpg'
import qrConference from './imgs/qrConferenceCrop.jpg'

function Home() {
    return (
        <div>
            <Links />

            <div className="fade-in">
                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className="col-lg-7 col-md-12 text-center">
                            <img src={conferenceCentre} alt="Conference Hall" width={800} className="img-fluid" />
                        </div>
                        <div className="col">
                            <h4 className="text-center fw-normal">CARIBBEAN AMERICAN HINDU ISSUES INC. INVITES YOU TO:</h4>

                            <div className="col align-items-center border border-dark border-2 mt-3">
                                <p className="pt-3 mt-3 text-center">CONFERENCE ON HINDUS IN THE CARIBBEAN AND THE DIASPORA</p>
                                <p className="pt-3 mt-3 text-center">On: Saturday October 19, 2024 8:30 AM - 4:30 PM</p>
                                <p className="pt-3 mt-3 text-center">Arthur Chung Convention Centre, Georgetown Guyana</p>
                                <p className="mt-4 pt-1 text-center"><a href="/info">More Info</a></p>
                            </div>

                            <h4 className="fw-normal mt-3">Register</h4>
                            <div className="row">
                                <div className="col-3">
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdqkpO8ddpFWAbB7QmV2GJcjP2vydrY95vaxcNfv7VP4ybtcA/viewform" target="_blank" rel="noreferrer"><img src={qrConference} width={150} className="img-fluid" alt="qr regestration code" /></a>
                                </div>
                                <div className="col mt-5 ms-4">
                                    <p>To register scan the QR code or click <a href="https://docs.google.com/forms/d/e/1FAIpQLSdqkpO8ddpFWAbB7QmV2GJcjP2vydrY95vaxcNfv7VP4ybtcA/viewform" target="_blank" rel="noreferrer">here</a>!</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default Home;