import React from "react";
import './style.css'

function Links() {
    return (
        <div>
            <span className="d-flex g-2 justify-content-end pe-3 d-none d-md-flex">
                <p><a href="/" className="text-decoration-none text-decoration-underline" style={{ color: 'black' }}>HOME</a></p>
                <p><a href="/board" className="text-decoration-none text-decoration-underline" style={{ color: 'black' }}>BOARD OF DIRECTORS</a></p>
                <p><a href="/info" className="text-decoration-none text-decoration-underline" style={{ color: 'black' }}>MORE INFO</a></p>
                <p><a href="/contact" className="text-decoration-none text-decoration-underline" style={{ color: 'black' }}>CONTACT</a></p>
            </span>

            <span className="d-flex g-2 justify-content-center pe-3 d-flex d-md-none">
                <p><a href="/" className="text-decoration-none text-decoration-underline small" style={{ color: 'black'}}>HOME</a></p>
                <p><a href="/board" className="text-decoration-none text-decoration-underline small" style={{ color: 'black' }}>BOARD OF DIRECTORS</a></p>
                <p><a href="/info" className="text-decoration-none text-decoration-underline small" style={{ color: 'black' }}>MORE INFO</a></p>
                <p><a href="/contact" className="text-decoration-none text-decoration-underline small" style={{ color: 'black' }}>CONTACT</a></p>
            </span>
        </div>
    );
}

export default Links