import React, { useState, useRef } from "react";
import Links from "./Links";
import emailjs from '@emailjs/browser';


function Contact() {
    const form = useRef();
    const [data, setData] = useState({ name: "", email: "", message: "" });
    const [loading, setLoading] = useState(false);

    function handleChange(event) {
        setData(prevData => {
            return {
                ...prevData, [event.target.name]: event.target.value
            }
        })
    }

    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true)
        if (data.email === "" || data.name === "" || data.message === "") {
            alert("Please make sure to fill out all fields");
        }
        else {
            emailjs
                .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, {
                    publicKey: process.env.REACT_APP_PUBLIC_KEY,
                })
                .then(
                    () => {
                        alert("Email was successfully sent! We will get back to you as soon as possible");
                    },
                    (error) => {
                        alert("Something went wrong, please try again.")
                        console.log(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, process.env.REACT_APP_PUBLIC_KEY)
                    },
                );

        }
        setLoading(false)
    }

    return (
        <div>
            <Links />
            <form className="text-center" onSubmit={handleSubmit} ref={form}>
                {loading && <p style={{ color: 'red' }}>Loading Please Wait...</p>}

                <div className="d-flex justify-content-center">
                    <div className="form-group">
                        <input type="text" placeholder="Name" className="text-center" onChange={handleChange} name="name" />
                    </div>
                    <div className="form-group">
                        <input type="email" placeholder="Email" className="text-center" onChange={handleChange} name="email" />
                    </div>
                </div>
                <br />
                <textarea placeholder="Message..." name="message" className="border border-black text-center form-width form-custom" style={{ padding: "5px", width: "400px", height: "100px" }} onChange={handleChange} />
                <br />
                <input type="submit" className="btn btn-dark mt-3" />
            </form>
        </div>
    );
}

export default Contact;