import React from "react";
import './style.css'

function BoardMembers(props) {
    return (
        <div className="fade-in">
            <div className="container pt-3 d-none d-lg-block">
                <div className="row">
                    <div className="col-3 d-flex align-items-center">
                        <img src={`./BioImgs/${props.img}`} width={280} className="img-fluid border border-dark border-3" alt={`${props.name}`}/>
                    </div>
                    <div className="col">
                        <h4 className="fw-normal text-center">{props.name}</h4>
                        <p className="message pt-3">{props.bio}</p>
                    </div>
                </div>
                <hr />

            </div>

            <div className="container pt-3 d-block d-lg-none">
                <div className="text-center"><img src={`./BioImgs/${props.img}`} width={100} className=" border border-dark border-3" alt={`${props.name}`}/></div>
                <h4 className="fw-normal text-center pt-3">{props.name}</h4>
                <p className="message pt-3">{props.bio}</p>

                <hr />

            </div>


        </div>
    );
}

export default BoardMembers;