import React from "react";
import BoardMembers from "./BoardMembers";
import Links from "./Links";
import Data from "./Data"

function Board() {
    var bio = Data.map(d=><BoardMembers key={d.id} {...d}/>)
    return (
        <div>
            <Links />

            {bio}

        </div >
    );
}

export default Board;
