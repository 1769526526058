import React from "react";
import Links from "./Links";
// import vijay from './imgs/vijayNarayanSingh.jpg'
import poster from './imgs/cahiiposter.jpg'

function Info() {
    return (
        <div>
            <Links />
            <img src={poster} className="mx-auto d-block img-fluid border border-4 border-dark" width={500} alt="poster"/>
            <div className="container-fluid fade-in">
                <h3 className="fw-normal mt-5">MEETING PURPOSE:</h3>
                <ul>
                    <li className="pt-2">To bring together Caribbean and Diaspora Hindus to discuss and analyse the challenges
                        faced in Health, Environment, Education, Society and Religion</li>
                    <li className="pt-2">To identify problems and opportunities</li>
                    <li className="pt-2">To work with Government agencies and NGOs to implement proposed solutions</li>
                </ul>

                <h3 className="fw-normal pt-3">OBJECTIVES</h3>
                <ul>
                    <li className="pt-2">Identify and analyse the challenges experienced and encountered by Caribbean and
                        Diaspora Hindus</li>
                    <li className="pt-2">Propose solutions</li>
                    <li className="pt-2">Produce a comprehensive document that identifies the strategies for the implementation of
                        all suggested solutions</li>
                    <li className="pt-2">Mobilise the Hindu community and all stakeholders to implement the solutions.</li>
                </ul>

                <h3 className="fw-normal pt-3">TARGET AUDIENCES</h3>
                <ul>
                    <li className="pt-2">Hindu leaders</li>
                    <li className="pt-2"> High-school and university students</li>
                    <li className="pt-2">Hindu women and youths</li>
                </ul>

                {/* <div className="card mb-3 mt-5" style={{width: '70%'}}>
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={vijay} className="img-fluid rounded-start" alt="keynote address Vijay Naraynsingh"/>
                        </div>
                        <div className="col-md-8 d-flex align-items-center text-center">
                            <div className="card-body">
                                <h5 className="card-title">Keynote Address</h5>
                                <p className="card-text">Professor Vijay Naraynsingh from Trinidad</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Info;