import React from "react";
import logo from './imgs/logo.png'
import './style.css'

function Header() {
    return (
        <div className="shadow-lg p-3 mb-4 bg-body rounded container-fluid header">
            <div className="row">
                <div className="col-1">
                    <a href="/"><img src={logo} width={100} alt="logo" className="d-none d-lg-block"/></a>
                    <a href="/"><img src={logo} width={85} alt="logo" className="d-none d-md-block d-lg-none"/></a>

                </div>
                <div className="col-10">
                    <h1 className="text-center fw-normal display-6 d-none d-lg-block">CARIBBEAN AMERICAN HINDU ISSUES INC.</h1>
                    <h1 className="text-center fw-normal display-6 fs-5 d-block d-lg-none">CARIBBEAN AMERICAN HINDU ISSUES INC.</h1>

                    <h5 className="text-center fw-normal fst-italic d-none d-md-block ">Addressing Challenges Facing Hindus in the Caribbean and the Diaspora </h5>
                </div>
            </div>

        </div>
    );
}

export default Header;