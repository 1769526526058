const data = [
    {
        id: 1,
        name: 'Pt. Roopnauth Sharma',
        bio: "Spiritual Leader and Practicing Hindu Priest at Ram Mandir in Mississauga.\n\n Immigrated to Canada in 1970 where over 40 years he was professionally employed in Banking, Information Technology and Manufacturing while serving his community as a Hindu Priest.\n\n He is the founder and Spiritual Leader of Mississauga’s Ram Mandir, a Hindu temple, where he attends to a community of over 5000 families from the Region of Peel and surrounding areas, on all religious and social matters.\n\n Pandit Sharma currently is engaged in senior positions in the following diverse community organizations\n\n • President of the Hindu Federation an Umbrella body representing significant Hindu Temples in Ontario\n • Member of the Interfaith Council of Peel that represents the multi-faith communities within the Peel Region and surrounding areas\n • Past President and current CEO of Canadian Multi-faith Federation (CMF) An umbrella body representing over thirty faith groups\n • President and Hindu Chaplin for Interfaith Chaplaincy (IFC) in support of Spiritual care services at Correctional Services Canada (CSC)\n • Founder and Spiritual Leader Mississauga Ram Mandir (Hindu Temple)\n • Member of G20 Interfaith Association Advisory Council\n • Member of Canada Revenue Agency (CRA) Technical Issues Working group (TIWG) \n\n Pandit Sharma has received many community service awards and is a recipient of the Queen Elizabeth’s Diamond Jubilee Medal \n\n Prior to retirement, Pandit Sharma was very engaged in the corporate business world. He has an extensive banking background combined with thirty years of information technology and management consulting experience. During this period, he served in many senior management positions in public and private corporations. Which includes Senior Manager PricewaterhouseCoopers, Manager Oracle, Manager Ernst Young Cap Gemini and Director Information Technology.\n\n Phone: #1 416-856-3810 Email: ptroopsharma@gmail.com",
        img: "ptsharma.png"
    },
    {
        id: 2,
        name: 'Dr. Kumar Mahabir',
        bio: "A full-time anthropologist at the University of Guyana (UG) and Fellow of The Eccles Centre for American Studies, British Library (2022-23). He is a former Assistant Professor at the University of Trinidad and Tobago (UTT). He obtained his Ph.D. in Anthropology from the University of Florida (UF).\n\n  As a doctoral student, he won a Florida Caribbean Institute Award, an A. Curtis Wilgus Grant, and an Organization of American States (OAS) Fellowship. Mahabir received a National Award (Hummingbird Silver Medal) for his contribution to education in his country in 2011. He was among 50 recipients who received a Distinguished Alumni Award from the UWI Alumni Association. Mahabir is the author of 12 books to date.",
        img: "DrKumarMahabir.jpg"
    },
    {
        id: 3,
        name: "Dr. Tara Singh",
        bio: "Educated at Mc Gillivary Canadian Mission School, Canal No 1, West Bank Demerara. Awarded a pass in Pupil Teachers’ 4th Year Exam. Taught at Mora Point Canadian Mission School (1966-1968). Attended UG and received a BSc degree (Soc Sc) and proceeded next to attend the University of Wales. Graduated there (1978) after 3 years with a PhD in Criminology. \n\n Received the following academic awards. \n\n 1. British Technical Assistance Award \n 2. British Commonwealth Award scholarship to the UK \n 3. Senior Visiting Commonwealth scholar Award \n 4. Fullbright Award \n\n Was a Senior Lecturer at UG in the Faculty of Social Sciences. Taught there for 6+ years (1978-1985). \n\n Received several citations for community and humanitarian work. President of a NGO named NYGM that does annual charitable work in Guyana, Jamaica and elsewhere.  The pursuit of charity is my strength as well as that of my family. \n\n Written and published countless articles on various aspects of social and economic life. Conducted several policy-oriented social researches in Guyana during 1978 to 1985. \n\n My strength has always been my family especially my wife.",
        img: "drTaraSingh.jpg"
    },
    {
        id: 4,
        name: "Dr. Indira Rampersad",
        bio: "DR INDIRA RAMPERSAD, is Head of Department of Political Science and Lecturer in Political Science/International Relations at The University of the West Indies (UWI), St. Augustine, Trinidad and Tobago. She is also an Attorney-at-law and was a Commissioner of the Equal Opportunities Commission of Trinidad and Tobago. She has also served as an election observer for the Organisation of American States (OAS) in Grenada, El Salvador and Guyana. Her doctoral dissertation focused on American foreign policy to Cuba. Her research now extends to Latin American and Caribbean Politics and International Relations, American foreign policy, alternative energy, food security and crime with specific emphasis on the Caribbean.",
        img: "IndiraRampersad.jpg"
    },
    {
        id: 5,
        name: "Vishnu Dutt",
        bio: "• Vishnu Dutt has been a journalist since 1972. \n • He has been associated with Arya Samaj USA since 1986 \n • Serving as a diplomat at the United Nations in 1993 he wrote speeches for Guyana's First Lady, Mrs Janet Jagan",
        img: "vishnuDutt.jpeg"
    }
]

export default data 